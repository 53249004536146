.progress {
  overflow: hidden;
  background-color: #eeeeee;
  border-radius: 4px;

  .progressBar {
    background-color: green;
    height: 5px;

    &.error {
      background-color: red;
    }
  }
}