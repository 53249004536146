@import '../../../assets/styles/variables';

.accountDetails {
  padding-bottom: 15px;

  @media (min-width: $breakingPointLg) {
    padding-bottom: 0;
  }

  form {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 30px;

    .formInput {
      flex: 0 0 100%;
      padding-left: 15px;
      padding-right: 15px;

      @media screen and (min-width: $breakingPointLg) {
        flex: 0 0 25%;

        button {
          width: 100%;
        }
      }
    }

    .button {
      margin-bottom: 15px;
    }
  }
}