// Colors
$grayPrimary: #213057;
$graySecondary: #192543;
$brandPrimary: #537bdf;
$brandSecondary: #4262b2;
$brandLight: #d4dbee;
$greenPrimary: #00977c;
$greenSecondary: #66c1b0;
$greenLight: #b3e0d8;
$eggshellPrimary: #e9e6dc;
$eggshellSecondary: #a6a39b;
$eggshellLight: #f8f8f4;
$orangePrimary: #eca613;
$orangeSecondary: #bd8510;
$orangeLight: #f5ca71;
$purplePrimary: #8e1c5a;
$purpleSecondary: #551136;
$purpleLight: #bb779c;
$redPrimary: #ea4858;
$redSecondary: #8c2b35;
$redLight: #ffc6cc;
$black: #000000;
$white: #ffffff;

// Borders
$borderColor: $eggshellPrimary;
$borderRadiusSmall: 0.125rem;
$borderRadiusMedium: 0.25rem;
$borderRadiusLarge: 0.375rem;
$borderRadiusExtraLarge: 0.5rem;

// Font
$fontColor: $grayPrimary;
$fontFamily: 'Grafik Web', sans-serif;
$fontSizeSmall: 0.875rem;
$fontSizeMedium: 1rem;
$fontSizeLarge: 1.125rem;
$fontWeightThin: 100;
$fontWeightExtraLight: 200;
$fontWeightLight: 300;
$fontWeightRegular: 400;
$fontWeightMedium: 500;
$fontWeightSemiBold: 600;
$fontWeightBold: 700;
$fontWeightBlack: 800;
$fontWeightSuper: 900;

// Breaking points
// Use with min width
$breakingPointXs: 0;
$breakingPointSm: 576px;
$breakingPointMd: 768px;
$breakingPointLg: 992px;
$breakingPointXl: 1200px;
$breakingPointXxl: 1600px;