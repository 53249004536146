@import '../../../assets/styles/variables';

.header {
  height: 88px;
  line-height: 14px;

  .fixed {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 88px;
    background: $grayPrimary 0% 0% no-repeat padding-box;
    color: white;
    box-shadow: 0px 5px 10px #18435A2F;
    z-index: 1;

    .logoDesktop {
      width: 131px;
      height: 18px;
      margin-left: 42px;
      margin-right: 30px;
      display: none;

      @media screen and (min-width: $breakingPointSm) {
        display: block;
      }
    }

    .logoMobile {
      height: 1.5rem;
      margin-left: 42px;
      margin-right: 25px;

      @media screen and (min-width: $breakingPointSm) {
        display: none;
      }
    }

    .container {
      display: flex;

      .language,
      .user {
        margin-right: 25px;

        @media (min-width: $breakingPointSm) {
          margin-right: 50px;
        }
      }
    }
  }
}