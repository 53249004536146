@import '../../assets/styles/variables';

.box {
  background: $white 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000017;
  border-radius: 16px;
  padding: 30px;
  margin: 30px 0 0;
}

.gridContainer {
  padding-bottom: 30px;

  .splitter {
    height: 0px;
    border: 1px solid $eggshellPrimary;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .titleContainer {
    display: flex;
    align-items: baseline;


    .circle {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 106px;
      min-height: 106px;
      background: $white 0% 0% no-repeat padding-box;
      border: 1px solid $eggshellPrimary;
      border-radius: 100%;

      svg {
        width: 32px;
        min-width: 32px;
      }
    }


    .largeTitle {
      font-size: 32px;
      line-height: 32px;
      font-weight: 500;
      letter-spacing: 0px;
      color: $grayPrimary;
      margin-bottom: 9px;
    }
  }

  .welcomeText {
    p {
      margin-bottom: 16px;
    }
  }
}

.marginLeft {
  margin-left: 30px;
}

.lightBold {
  font-weight: 500;
}

.bold {
  font-weight: 600;
}

.mb0 {
  margin-bottom: 0;
}