@import '../../../assets/styles/_variables.scss';

.formItem {
  position: relative;
  margin-bottom: 1.325rem;
  padding-bottom: 1rem;
}

.formItemError {
  bottom: 0;
  color: $redPrimary;
  font-size: 0.75rem;
  left: 0;
  line-height: 1;
  margin: 0;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
