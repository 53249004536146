@import '../../../assets/styles/variables';

.title {
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  letter-spacing: 0px;
  color: $grayPrimary;
  margin-top: 0;
  margin-bottom: 30px;
}