@import '../../../assets/styles/variables';

.languageGroup {
  display: flex;
  align-items: center;
}

.languageDropdown {
  &.dark {
    ul {
      background: $grayPrimary;
    }

    .languageDropdownItem {
      .dropdownOption {
        color: white;
      }
    }
  }

  ul {
    padding-left: 40px;
    padding-right: 40px;
  }

  .languageDropdownItem {
    padding-left: 0;
    padding-right: 0;

    &:hover {
      background-color: transparent;
    }

    &:not(:last-child) {
      border-bottom: 1px solid rgba(212, 219, 238, 0.3);
    }

    .dropdownOption {
      display: flex;
      margin: 12px 0 8px;

      &:hover {
        color: $brandPrimary;
      }

      .languageName {
        margin-left: 15px;
      }
    }
  }
}

.languageIcon {
  width: 22px;
  height: 22px;
  /* UI Properties */
  border: 1px solid $eggshellPrimary;
  border-radius: 100%;
}

.iconWhite {
  fill: white !important;
}
