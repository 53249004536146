@import '../../../assets/styles/variables';

.documentFile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 350px;
  height: 80px;
  border: 1px solid $eggshellPrimary;
  border-radius: 8px;
  padding: 20px;
  overflow: hidden;

  &:hover {
    color: $brandPrimary;
    border-color: $brandPrimary;
  }

  &.delete {
    color: inherit;
    border-color: $redPrimary;
  }

  .container {
    display: flex;
    align-items: center;
    flex-grow: 1;
    overflow: hidden;

    .fileIcon {
      height: 40px;
      fill: #c8c6b9;
    }

    .labelContainer {
      flex-grow: 1;
      overflow: hidden;

      .label {
        color: inherit;
        cursor: pointer;
        text-decoration: none;
        overflow: hidden;

        .truncate {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .deleteIcon {
    min-width: 20px;
    height: 20px;
    cursor: pointer;
    fill: #c8c6b9;

    &.delete {
      fill: $redPrimary;
    }
  }

  .type {
    color: $eggshellSecondary;
    line-height: 1;
    font-size: 12px;
  }
}
