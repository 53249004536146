@import '../../../assets/styles/variables';

.text {
  margin-bottom: 30px;
}

.documentsContainer {
  display: flex;
  flex-wrap: wrap;

  .document {
    margin-right: 30px;
    margin-bottom: 30px;
  }
}

.dropBoxWrapper {
  display: flex;
  column-gap: 30px;
}

.dropBoxItem {
  flex: 1;
}

.dropBoxLabel {
  font-weight: $fontWeightSemiBold;
  margin-bottom: 4px;
}

.dropBox {
  background: $eggshellLight 0% 0% no-repeat padding-box;
  border: 1px dashed $eggshellPrimary;
  border-radius: 8px;
  padding: 30px;
  text-align: center;
  width: 100%;

  .dragText {
    margin-top: 0;
    margin-bottom: 20px;
  }
}
