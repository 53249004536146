@import '../../../../assets/styles/variables';

.userGroup {
  display: flex;
  align-items: center;
}

.userDropdown {
  ul {
    background: $grayPrimary;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 60px;
    padding-bottom: 20px;

    li {
      color: white;
    }

    .loggedInText {
      color: #55577c;
    }

    .dropdownItem {
      padding: 20px 0;

      &:hover {
        background-color: transparent;
      }

      &.user {
        pointer-events: none;
      }

      &:not(:last-child) {
        border-bottom: 1px solid rgba(212, 219, 238, 0.3);
      }
    }
  }
}

.userIcon {
  width: 22px;
  height: 22px;
  /* UI Properties */
  border: 1px solid $eggshellPrimary;
  border-radius: 100%;
  background-color: white;
}

.iconWhite {
  fill: white !important;
}
