@import './variables';

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  color: $grayPrimary;
  font-family: Graphik Web;
  font-size: 14px;
  line-height: 22px;
  margin: 0;

  &.authentication {
    background-color: white;
  }

  &.authorized {
    background-color: $eggshellLight;
  }
}

p {
  margin: 0;
}