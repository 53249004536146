.linkedInButton {
  width: 100%;
  background-color: #388ABE;
  margin-bottom: 30px;
  border-radius: 4px;
  padding-left: 13px;
  padding-right: 37px;

  .content {
    display: flex;
    justify-content: space-between;
    width: 100%;

    svg {
      width: 24px;
    }
  }
}