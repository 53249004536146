@import '../../../assets/styles/variables';

.logoBox {
  display: none;
  text-align: center;

  .logo {
    height: 18px;
  }

  .title {
    font-size: 16px;
    margin-top: 30px;
  }

  @media (min-width: $breakingPointSm) {
    display: block;
  }
}