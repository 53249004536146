@import '../../../assets/styles/variables';

.splitter {
  display: flex;
  align-items: center;
  color: $eggshellSecondary;
  width: 100%;

  div {
    height: 0;
    border-top: 1px solid $eggshellSecondary;
    flex-grow: 1;
  }

  .or {
    margin: 0 15px;
  }
}