@import '../../../assets/styles/variables';

.header {
  height: 60px;
  line-height: 14px;

  .fixed {
    position: fixed;
    display: flex;
    background-color: white;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    padding: 16px 10px;
    z-index: 1;

    .logo {
      display: block;
      width: 120px;
      margin-right: 15px;

      @media (min-width: $breakingPointSm) {
        display: none;
      }
    }
  }
}