@import '../../assets/styles/variables';

.gridContainer {
  padding-bottom: 30px;

  .container {
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 100%;
    max-width: 370px;
    margin-top: 30px;

    form {
      width: 100%;
    }

    .lastBox {
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      margin-top: 30px;
    }
  }
}

.w100 {
  width: 100%;
}

.marginTop {
  margin-top: 30px;
}

.marginLeft {
  margin-left: 5px;
}

.passwordContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-bottom: 0.25rem;
}


.pleaseMessage {
  font-weight: 500;
  font-size: 16px;
}

.resendEmail {
  color: $grayPrimary;

  .title {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  }

  .confirmButtonContainer {
    display: flex;
    justify-content: center;
  }
}

.termsAndConditions {
  margin-top: 15px;
  line-height: 1.25;
}