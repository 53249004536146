// Fonts
@font-face {
  font-family: 'Graphik Web';
  src: url('../../assets/fonts/Graphik-SuperItalic-Web.woff2') format('woff2'),
    url('../../assets/fonts/Graphik-SuperItalic-Web.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('../../assets/fonts/Graphik-Super-Web.woff2') format('woff2'),
    url('../../assets/fonts/Graphik-Super-Web.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('../../assets/fonts/Graphik-BlackItalic-Web.woff2') format('woff2'),
    url('../../assets/fonts/Graphik-BlackItalic-Web.woff') format('woff');
  font-weight: 800;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('../../assets/fonts/Graphik-Black-Web.woff2') format('woff2'),
    url('../../assets/fonts/Graphik-Black-Web.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('../../assets/fonts/Graphik-BoldItalic-Web.woff2') format('woff2'),
    url('../../assets/fonts/Graphik-BoldItalic-Web.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('../../assets/fonts/Graphik-Bold-Web.woff2') format('woff2'),
    url('../../assets/fonts/Graphik-Bold-Web.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('../../assets/fonts/Graphik-SemiboldItalic-Web.woff2') format('woff2'),
    url('../../assets/fonts/Graphik-SemiboldItalic-Web.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('../../assets/fonts/Graphik-Semibold-Web.woff2') format('woff2'),
    url('../../assets/fonts/Graphik-Semibold-Web.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('../../assets/fonts/Graphik-MediumItalic-Web.woff2') format('woff2'),
    url('../../assets/fonts/Graphik-MediumItalic-Web.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('../../assets/fonts/Graphik-Medium-Web.woff2') format('woff2'),
    url('../../assets/fonts/Graphik-Medium-Web.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('../../assets/fonts/Graphik-RegularItalic-Web.woff2') format('woff2'),
    url('../../assets/fonts/Graphik-RegularItalic-Web.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('../../assets/fonts/Graphik-Regular-Web.woff2') format('woff2'),
    url('../../assets/fonts/Graphik-Regular-Web.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('../../assets/fonts/Graphik-LightItalic-Web.woff2') format('woff2'),
    url('../../assets/fonts/Graphik-LightItalic-Web.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('../../assets/fonts/Graphik-Light-Web.woff2') format('woff2'),
    url('../../assets/fonts/Graphik-Light-Web.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('../../assets/fonts/Graphik-ExtralightItalic-Web.woff2') format('woff2'),
    url('../../assets/fonts/Graphik-ExtralightItalic-Web.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('../../assets/fonts/Graphik-Extralight-Web.woff2') format('woff2'),
    url('../../assets/fonts/Graphik-Extralight-Web.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('../../assets/fonts/Graphik-ThinItalic-Web.woff2') format('woff2'),
    url('../../assets/fonts/Graphik-ThinItalic-Web.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('../../assets/fonts/Graphik-Thin-Web.woff2') format('woff2'),
    url('../../assets/fonts/Graphik-Thin-Web.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

// $font-family: 'Graphik Web' !default;
// $font-family-black: 'Graphik Web' !default;
// $font-family-blackitalic: 'Graphik Web' !default;
// $font-family-bold: 'Graphik Web' !default;
// $font-family-bolditalic: 'Graphik Web' !default;
// $font-family-light: 'Graphik Web' !default;
// $font-family-lightitalic: 'Graphik Web' !default;
// $font-family-medium: 'Graphik Web' !default;
// $font-family-mediumitalic: 'Graphik Web' !default;
// $font-family-regular: 'Graphik Web' !default;
// $font-family-regularitalic: 'Graphik Web' !default;
// $font-family-thin: 'Graphik Web' !default;
// $font-family-thinitalic: 'Graphik Web' !default;

// html,
// body {
//   font-family: $font-family-regular;
//   font-feature-settings: 'liga', 'kern';
//   color: $grayPrimary;
// }

// .slim {
//   font-weight: 100 !important;
// }

// .f-medium {
//   font-family: $font-family-medium;
// }

// .bold {
//   font-weight: 800 !important;
// }

// p {
//   @include font-size($p-font-sizes);
// }